import {Variant} from "@common/service/variants";
import {API} from "@common/service/api";
import {ApiResponse, Pagination} from "@common/service/models";

export type Product = {
  name: string,
  price: number,
  image: string
}

export type ProductBulkDiscounts = {
  amount: number,
  price: number
}

export interface DetailedProduct {
  id: string,
  name: string,
  price: number,
  images: string[]
  code: string,
  description: string,
  bulkDiscounts: ProductBulkDiscounts[],
  variants: Variant[]
}

export type ProductResponse = ApiResponse<{
  pagination: Pagination,
  products: Product[]
}>

export interface ProductGroupFilters {
  id: string,
  name: string,
  amount: number
}

export type ProductFiltersResponse = ApiResponse<{
  productGroups: ProductGroupFilters[]
}>

export interface productFilters {
  productGroup?: string[],
  limit: number,
  page: number
}

export const getProducts = async (filters: productFilters): Promise<ProductResponse> => {
  const params = {
    productGroup: filters.productGroup?.join(",") || undefined,
    limit: filters.limit,
    page: filters.page
  }

  const res = await API.get('/products', { params })

  return res.data
}

export const getProductFilters = async (): Promise<ProductFiltersResponse> =>
  (await API.get('/products/filters')).data

export const getFeaturedProducts = async (): Promise<Product[]> =>
  (await API.get('/products/featured')).data

export const findProduct = async (name: string): Promise<DetailedProduct> =>
  (await API.get(`/products/${name}`)).data
