import {action, computed, makeObservable, observable} from "mobx"
import {CartItem, getCartItems, saveCartItems} from "@common/storage/cartStorage";
import {v1 as uuid} from "uuid";
import {DetailedProduct, Variant} from "@common/service";
import {logAddToCart} from "@common/util";

export class CartStore {
  @observable private _cart: CartItem[] = getCartItems()

  static new = () => new CartStore()

  private constructor() {
    makeObservable(this)
  }

  @computed
  get cart() {
    return this._cart
  }

  addToCart(product: DetailedProduct, variant: Variant, amount: number) {
    logAddToCart(product.name, variant.name, amount)
    const existingItem = this.cart.find(
      item => item.productId === product.id && item.variantId === variant.id
    )

    if (existingItem) existingItem.amount = amount
    else this.setCart([...this.cart, {
      id: uuid(),
      productId: product.id,
      variantId: variant.id,
      amount
    }])
  }

  removeFromCart = (productId: string, variantId: string) => {
    const newCart = this.cart.filter(item =>
      item.productId !== productId || item.variantId !== variantId
    )

    if (this.cart.length !== newCart.length) {
      this.setCart(newCart)
    }
  }

  clearCart = () => this.setCart([])

  @action private setCart = (newCart: CartItem[]) => {
    this._cart = newCart

    saveCartItems(newCart)
  }
}