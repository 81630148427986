import {FormControlLabel, Grid, Typography} from '@material-ui/core'
import React from 'react'
import {ProductListStore} from "./ProductListStore";
import {observer} from "mobx-react";
import {Checkbox, FormGroup} from "@mui/material";

export const ProductSearch = observer(({store}: { store: ProductListStore}) => {
  return <Grid item container xs={3} direction='column' className="product-search">
    <Typography variant="h4">Kingituste tüübid</Typography>
    <FormGroup>
      {store.productGroups.map(productGroup =>
        <FormControlLabel
          key={productGroup.id}
          control={<Checkbox checked={productGroup.active} onClick={() => store.toggleProductGroup(productGroup.id)} />}
          label={<span>{productGroup.name} <span className="filter-amount">[{productGroup.amount}]</span></span>} />
      )}
    </FormGroup>
  </Grid>
})
