import {action, computed, makeObservable, observable} from "mobx";
import {sendMessage} from "@common/service";
import React from "react";
import {AlertStore} from "@common/stores/AlertStore";
import {RootStore} from "@common/stores";
import {Store} from "@common/stores";

const EMAIL_PATTERN = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/
const NAME_MISSING_ERROR = "Nimi on puudu"
const EMAIL_MISSING_OR_INVALID_ERROR = "Email on vale või puudu"
const MESSAGE_MISSING_ERROR = "Sõnum on puudu"

export class ContactStore implements Store {
  @observable private alertStore: AlertStore

  @observable private _isLoading: boolean = false

  @observable.ref protected _name: string = ""
  @observable.ref protected _email: string = ""
  @observable.ref protected _message: string = ""
  @observable.ref protected _nameError?: string
  @observable.ref protected _emailError?: string
  @observable.ref protected _messageError?: string

  static new = (store: RootStore) => new ContactStore(store)

  private constructor({ alertStore }: RootStore) {
    makeObservable(this)

    this.alertStore = alertStore
  }

  @action.bound
  async send(e: React.FormEvent) {
    e.preventDefault()
    this.toggleLoading()

    if (!this._name) this.setNameError(NAME_MISSING_ERROR)
    else this.setNameError()

    if (!EMAIL_PATTERN.test(this._email)) this.setEmailError(EMAIL_MISSING_OR_INVALID_ERROR)
    else this.setEmailError()

    if (!this._message) this.setMessageError(MESSAGE_MISSING_ERROR)
    else this.setMessageError()

    if (this.isErrored) {
      this.toggleLoading()

      return
    }

    try {
      await sendMessage({name: this.name, email: this.email, message: this.message})

      this.setName("")
      this.setEmail("")
      this.setMessage("")

      this.alertStore.showSuccess("Sõnum saadetud")
    } catch (err) {
      this.alertStore.showError("Midagi läks valesti, proovige hiljem uuesti")

      throw err
    } finally {
      this.toggleLoading()
    }
  }

  @computed
  get isLoading(): boolean {
    return this._isLoading
  }

  @computed
  get name(): string {
    return this._name
  }

  @computed
  get email(): string {
    return this._email
  }

  @computed
  get message(): string {
    return this._message
  }

  @computed
  get nameError(): string | undefined {
    return this._nameError
  }

  @computed
  get emailError(): string | undefined {
    return this._emailError
  }

  @computed
  get messageError(): string | undefined {
    return this._messageError
  }

  @computed
  private get isErrored(): boolean {
    return !!this.nameError || !!this.emailError || !!this.messageError
  }

  @action toggleLoading = () => this._isLoading = !this._isLoading
  @action setName = (name: string) => this._name = name
  @action setEmail = (email: string) => this._email = email
  @action setMessage = (message: string) => this._message = message

  @action private setNameError = (nameError?: string) => this._nameError = nameError
  @action private setEmailError = (emailError?: string) => this._emailError = emailError
  @action private setMessageError = (messageError?: string) => this._messageError = messageError
}