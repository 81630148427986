import React, {useEffect} from 'react'
import ReactMetaTags from 'react-meta-tags'
import {logViewPage} from "@common/util";

export type MetaTagsProps = {
	title: string
	description: string
	link: string
	image?: string
}

export const MetaTags = React.memo<MetaTagsProps>(({ title, description, link, image}) => {
	useEffect(() => {
		logViewPage(title)
	}, [])

	return (
		<ReactMetaTags>
			<title>Lelulaeka Jõulukontor - {title}</title>
			<meta name="description" content={description} />

			<meta property="og:title" content={title} />
			<meta name="og:description" content={description} />
			{image && <meta property="og:image" content={image} />}
			<meta property="og:url" content={`https://www.joulukontor.ee${link}`} />
			<meta property="og:site_name" content="Lelulaeka Jõulukontor" />

			<meta property="twitter:card" content={image ? 'summary_large_image' : 'summary'} />
			<meta property="twitter:title" content={title} />
			<meta name="twitter:description" content={description} />
			{image && <meta property="twitter:image" content={image} />}
		</ReactMetaTags>
	)
}
)
