import {action, computed, makeObservable, observable} from "mobx";
import {
  DeliveryMethod,
  Order,
  OrderPreviewProductResponse,
  OrderPreviewResponse,
  previewOrder,
  submitOrder
} from "@common/service";
import {CartStore} from "@common/stores";
import {DateTime} from "luxon";
import {ContactFormData} from "@common/components";
import {normalizePrice} from "@common/util";
import {AlertManager} from "react-alert";

export const MIN_DATE = DateTime.now().plus({ days: 14 })

export class CartPageStore {
  @observable private _orderPreview?: OrderPreviewResponse

  @observable private _showBackdrop = false

  @observable private _deliveryDate: DateTime = MIN_DATE
  @observable private _deliveryMethod: DeliveryMethod = DeliveryMethod.PICK_UP

  static new = (cartStore: CartStore, alertManager: AlertManager) => new CartPageStore(cartStore, alertManager)

  private constructor(private cartStore: CartStore, private alertManager: AlertManager) {
    makeObservable(this)
  }

  async init() {
    this.setOrderPreview(await previewOrder(this.cartStore.cart))
  }

  @action.bound
  performCheckout(contacts: ContactFormData) {
    const data: Order = {
      personName: contacts.name,
      email: contacts.email,
      message: contacts.message,
      phone: contacts.phone,
      companyName: contacts.companyName,
      deliveryDate: this._deliveryDate.toJSDate(),
      deliveryMethod: this._deliveryMethod,
      orderItems: this.cartStore.cart
    }

    submitOrder(data)
      .then(() => {
        this.cartStore.clearCart()
        this.alertManager.success('Teie tellimus on edastatud')
      })
      .catch(() => this.alertManager.error('Teie tellimust ei olnud võimalik edastada, proovige hiljem uuesti'))
      .finally(() => this.setShowBackdrop(false))
  }

  @computed
  get isLoading(): Boolean {
    return this._orderPreview === undefined
  }

  @computed
  get isCartEmpty(): Boolean {
    return this._orderPreview!.products.length === 0
  }

  @computed
  get deliveryDate(): DateTime {
    return this._deliveryDate
  }

  @computed
  get deliveryMethod(): DeliveryMethod {
    return this._deliveryMethod
  }

  @computed
  get cartItems(): OrderPreviewProductResponse[] {
    return this._orderPreview?.products || []
  }

  @computed
  get showBackdrop(): boolean {
    return this._showBackdrop
  }

  @computed
  get finalPrice(): string {
    let total = this._orderPreview!.finalPrice * 100
    if (this._deliveryMethod === DeliveryMethod.COURIER) total += 25 * 100

    return `${normalizePrice(Math.round(total) / 100)} €`
  }

  @computed
  get finalPriceWithVat(): string {
    let total = this._orderPreview!.finalPriceWithVat * 100
    if (this._deliveryMethod === DeliveryMethod.COURIER) total += 25 * 100 * 1.2

    return `${normalizePrice(Math.round(total) / 100)} €`
  }

  @action setDeliveryDate = (date: DateTime) => this._deliveryDate = date
  @action setDeliveryMethod = (method: DeliveryMethod) => this._deliveryMethod = method

  @action setShowBackdrop = (show: boolean) => this._showBackdrop = show
  @action private setOrderPreview = (order: OrderPreviewResponse) => this._orderPreview = order
}