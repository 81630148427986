import React, {useMemo} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import {Routes} from "./pages";
import {useRootStore} from "@common/stores";
import {checkFeature} from "@common/util";

export const ClientRouter = () => {
  const { featureStore } = useRootStore()

  const EnabledRoutes = useMemo(
    () => Routes.filter(link => checkFeature(link, featureStore)),
    [featureStore, Routes]
  )

  return (
    <Router>
      <Switch>
        {EnabledRoutes.map((route, i) => <Route key={i} {...route} />)}
      </Switch>
    </Router>
  )
}