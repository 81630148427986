import React from 'react'
import { AlertComponentPropsWithStyle } from "react-alert"
import { Alert } from 'react-bootstrap'

export const FlashMessage = React.memo<AlertComponentPropsWithStyle>(({ message, options, style, close }) => {
  return (
    <Alert variant={options.type === 'error' ? 'danger' : options.type}>
      {message}
    </Alert>
  )
})
