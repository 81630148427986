import React, { useState } from 'react'
import { Tab } from '@material-ui/core'
import { Skeleton, TabContext, TabList, TabPanel } from '@material-ui/lab'
import {Spacer} from "@common/components";
import {BulkPricesProductTab} from "./BulkPricesProductTab";
import {BulkPricesTabData} from "../../ProductViewStore";

export type ProductTabsProps = {
  description: string,
  bulkPricesData: BulkPricesTabData
}

export const ProductTabs = React.memo<ProductTabsProps>(({ description, bulkPricesData }) => {
  const [activeTab, setActiveTab] = useState("DESCRIPTION")

	const descriptionText = description ? description.split(/\n\r?/g).map(x => x === '' ? <br /> : <p>{x}</p>) : (
    <div>
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
      <Skeleton animation='wave' />
      <Skeleton style={{ width: '80%' }} animation='wave' />
    </div>
  )

  return (
		<div>
			<Spacer />
			<TabContext value={activeTab}>
				<TabList onChange={(_, tab) => setActiveTab(tab)}
					indicatorColor="primary"
					textColor="primary">
					<Tab label="Kirjeldus" value={"DESCRIPTION"} />
          <Tab disabled={bulkPricesData.rows.length === 0} label="Hulgihinnad" value={"BULK_PRICES"}/>
				</TabList>
				<TabPanel value="DESCRIPTION">{descriptionText}</TabPanel>
        <BulkPricesProductTab data={bulkPricesData} />
			</TabContext>
		</div>
  )
})
