import {initializeApp} from "@firebase/app";
import {getAnalytics, logEvent} from "@firebase/analytics";
import {FIREBASE_CONFIG} from "@common/util/config";

const app = initializeApp(FIREBASE_CONFIG);
const analytics = getAnalytics(app);

enum AnalyticEvents {
  VIEW_PAGE = "page_view",
  VIEW_PRODUCT = "view_product",
  ADD_TO_CART = "add_to_cart"
}

export const logViewPage = (title: string) =>
  logEvent(analytics, AnalyticEvents.VIEW_PAGE, {
    page_title: title,
    page_path: window.location.pathname
  })

export const logViewProduct = (productName: string) =>
  logEvent(analytics, AnalyticEvents.VIEW_PRODUCT, {
    product_name: productName
  })

export const logAddToCart = (productName: string, variantName: string, amount: number) =>
  logEvent(analytics, AnalyticEvents.ADD_TO_CART, {
    productName, variantName, amount
  })