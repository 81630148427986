import React, {useContext, useEffect, useState} from "react";
import {observer} from "mobx-react";
import {RootStore} from "@common/stores";
import {useAlert} from "react-alert";

const RootContext = React.createContext({} as RootStore)

export const useRootStore = () => useContext(RootContext)

export const RootProvider = observer((props: any) => {
  const alert = useAlert()
  const [ store ] = useState(RootStore.new)

  useEffect(() => { store.init(alert) }, [])

  return (<RootContext.Provider value={store}>{store.isLoading ? <React.Fragment /> : props.children}</RootContext.Provider>)
})
