import {Divider, FormControlLabel, Grid, Radio, RadioGroup} from "@material-ui/core";
import React from "react";
import {ProductVariant} from "./ProductViewStore";

export interface VariantsSectionProps {
  variants: ProductVariant[]
  variantId: string
  setVariant: (variantId: string) => void
}

export const VariantsSection = ({ variants, variantId, setVariant }: VariantsSectionProps) => {
  if (variants.length <= 0) return null

  return (
    <Grid item xs={12}>
      <h5>Vali paki tüüp:</h5>
      <RadioGroup value={variantId} onChange={(e) => setVariant(e.target.value)}>
        {variants.map(variant => {
          return <FormControlLabel
            key={variant.id}
            value={variant.id}
            control={<Radio />}
            label={variant.label} />
        })}
      </RadioGroup>
      <Divider />
    </Grid>
  )
}