import {API} from "@common/service/api";

export interface OrderPreviewProductResponse {
  productId: string
  productName: string
  variantId: string
  variantName: string
  amount: number
  finalPrice: number
  finalPriceWithVat: number
}

export interface OrderPreviewResponse {
  products: OrderPreviewProductResponse[]
  finalPrice: number
  finalPriceWithVat: number
}

export interface OrderItem {
  productId: string
  variantId: string
  amount: number
}

export enum DeliveryMethod {
  COURIER = "COURIER",
  PICK_UP = "PICK_UP"
}

export interface Order {
  orderItems: OrderItem[],
  deliveryDate: Date
  deliveryMethod: DeliveryMethod
  companyName: string
  personName: string
  email: string
  phone: string
  message: string
}

export const previewOrder = async (items: OrderItem[]): Promise<OrderPreviewResponse> =>
  (await API.post("/orders/preview", items)).data

export const submitOrder = async (order: Order): Promise<void> =>
  API.post("/orders/place", order)