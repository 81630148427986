import {CartStore} from "@common/stores";
import {action, computed, makeObservable, observable} from "mobx";
import {DetailedProduct, findProduct, Variant} from "@common/service";
import {formatPrice, logViewProduct} from "@common/util";

export interface ProductVariant {
  id: string
  label: string
}

export interface BulkPricesTabData {
  labels: string[]
  rows: string[][]
}

export class ProductViewStore {
  @observable private _product?: DetailedProduct = undefined
  @observable private _activeVariant?: Variant = undefined
  @observable private _amount: number = 1

  static new = (cartStore: CartStore) => new ProductViewStore(cartStore)

  private constructor(
    private cartStore: CartStore
  ) {
    makeObservable(this)
  }

  async init(name: string) {
    const res = await findProduct(name)

    logViewProduct(res.name)

    this.setProduct(res)
    this.setVariant(res.variants[0].id)
  }

  @computed
  get isLoading() {
    return this._product === undefined
  }

  @computed
  get amount() {
    return this._amount
  }

  @computed
  get productCode() {
    return `Toote kood: ${this._product!.code}`
  }

  @computed
  get name(): string {
    return this._product?.name || ''
  }

  @computed
  get description(): string {
    return this._product?.description || ''
  }

  @computed
  get price(): string {
    return formatPrice(
      Math.floor((this._product?.price || 0) * 100 + (this._activeVariant?.priceModification || 0) * 100) / 100
    )
  }

  @computed
  get images(): string[] {
    return this._product?.images || []
  }

  @computed
  get bulkDiscountsTabData(): BulkPricesTabData {
    const bulkDiscounts = this._product?.bulkDiscounts || []

    const rows = bulkDiscounts.map((discount, i) => {
      const nextAmount = bulkDiscounts[i + 1]?.amount
      const row = [`${discount.amount + 1}${nextAmount ? ` - ${nextAmount}` : '+'}`]

      this._product?.variants.forEach(variant => {
        const price = (Math.round((variant.priceModification * 100) + (discount.price * 100)) / 100)
        row.push(formatPrice(price))
      })

      return row
    })

    const labels = this._product!.variants.map(variant => variant.name)

    labels.unshift("Kogus")

    return {
      labels,
      rows
    }
  }

  @computed
  get hasVariants() {
    return this._product!.variants.length > 0
  }

  @computed
  get activeVariant() {
    return this._activeVariant?.id || "0"
  }

  @computed
  get variants(): ProductVariant[] {
    return this._product!.variants.map(variant => ({
      id: variant.id,
      label: this.createProductVariantLabel(variant)
    }))
  }

  setVariant(id: string) {
    this.setActiveVariant(this._product!.variants.find(item => item.id === id)!)
  }

  saveToCart() {
    this.cartStore.addToCart(this._product!, this._activeVariant!, this._amount)
  }

  private createProductVariantLabel(variant: Variant, withNewLine: boolean = false): string {
    if (variant.priceModification === 0) return variant.name

    if (withNewLine) return `${variant.name}\n(${formatPrice(variant.priceModification, { prefixWithPositiveSymbol: true })})`
    else return `${variant.name} (${formatPrice(variant.priceModification, { prefixWithPositiveSymbol: true })})`
  }

  @action setAmount = (amount: number) => this._amount = amount
  @action private setProduct = (newProduct: DetailedProduct) => this._product = newProduct
  @action private setActiveVariant = (newVariant: Variant) => this._activeVariant = newVariant
}