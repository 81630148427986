import { Button, Container, Grid, TextField } from '@material-ui/core'
import React, { useState } from 'react'

export type ContactFormData = {
	name: string,
	companyName: string,
  email: string,
  message: string,
	phone: string
}

export type ContactsFormProps = {
  children?: React.ReactNode,
  onCancel?: () => void,
  onSubmit: (data: ContactFormData) => void,
  dontValidateDescription?: boolean
}

export const ContactsForm = React.memo<ContactsFormProps>(({ dontValidateDescription, onCancel, onSubmit, children }) => {
	const [name, setName] = useState('')
	const [companyName, setCompanyName] = useState('')
  const [email, setEmail] = useState('')
  const [message, setMessage] = useState('')
	const [phone, setPhone] = useState('')

	const [nameError, setNameError] = useState(false)
  const [emailError, setEmailError] = useState(false)
	const [messageError, setMessageError] = useState(false)
	const [phoneError, setPhoneError] = useState(false)

  function submit() {
    const emailPattern = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/

		if (!name) {
			setNameError(true)
      return
		} else setNameError(false)

    if (!emailPattern.test(email)) {
      setEmailError(true)
      return
    } else setEmailError(false)

    if (!dontValidateDescription && !message) {
      setMessageError(true)
      return
		} else setPhoneError(false)

		if (!phone) {
			setPhoneError(true)
			return
		} else setMessageError(false)

    onSubmit({ name, companyName, email, message, phone})
  }

  return (
    <Container maxWidth='sm'>
      {children}
      <form>
        <Grid
          container
          alignItems='center'
          justifyContent='center'
          spacing={4}>
          <Grid item sm={6} xs={12}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              id='name'
              autoComplete='off'
							label='Kontaktisiku nimi'
              size='small'
              fullWidth
							error={nameError} />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
							value={companyName}
              onChange={(e) => setCompanyName(e.target.value)}
              id='company-name'
              autoComplete='off'
              label='Ettevõtte nimi'
              size='small'
              fullWidth />
          </Grid>
          <Grid item sm={6} xs={12}>
            <TextField
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              id='email'
							label='E-Posti address'
              type='email'
              size='small'
              fullWidth
              error={emailError} />
					</Grid>
					<Grid item sm={6} xs={12}>
						<TextField
							value={phone}
							onChange={(e) => setPhone(e.target.value)}
							id='phone'
							label='Telefon'
							type='numbers'
							size='small'
							fullWidth
							error={phoneError} />
					</Grid>
          <Grid item xs={12}>
            <TextField
              value={message}
              onChange={(e) => setMessage(e.target.value)}
              autoComplete='off'
              id='message'
              label='Lisainfo'
              key='message'
              fullWidth
              variant='filled'
              multiline
              minRows={5}
              maxRows={10}
              error={messageError} />
          </Grid>
          <Grid item xs={12} container spacing={1} justifyContent={onCancel ? 'flex-end' : 'center'} alignItems='center'>
            {onCancel && <Grid item><Button onClick={onCancel} className='MuiButton-error' >Muuda tellimus</Button></Grid>}
						<Grid item><Button onClick={submit} variant="contained" color="primary">Saada</Button></Grid>
          </Grid>
        </Grid>
      </form>
    </Container>
  )
})
