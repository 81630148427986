import { Container, Grid } from '@material-ui/core'
import React from 'react'
import {PageTitle, MetaTags, TextField} from '@common/components'
import {observer} from "mobx-react";
import {ContactStore} from "./ContactStore";
import {useStore} from "@common/stores";
import {LoadingButton} from "@mui/lab";
import SendIcon from '@material-ui/icons/Send';
import {Paths} from "@common/util";

export const ContactsPage = observer(() => {
	const store = useStore(ContactStore.new)

  return (
		<Container maxWidth='sm'>
			<MetaTags
				title='Kontakt'
				description=''
				link={Paths.CONTACT}
			/>
			<PageTitle>Kontakt</PageTitle>
      <form>
        <Grid
          container
          alignItems='center'
          justifyContent='center'
					spacing={4}>
					<TextField
						grid={{ sm: 6, xs: 12 }}
						value={store.name}
						onChange={store.setName}
						name='Nimi'
						error={store.nameError}
						disabled={store.isLoading} />
					<TextField
						grid={{ sm: 6, xs: 12 }}
						value={store.email}
						onChange={store.setEmail}
						autoComplete
						name='E-Posti address'
						error={store.emailError}
						disabled={store.isLoading} />
					<TextField
						grid={{ xs: 12 }}
						value={store.message}
						onChange={store.setMessage}
						name='Tekst'
						rows={5}
						error={store.messageError}
						disabled={store.isLoading} />
				</Grid>
				<Grid item xs={12} container spacing={1} justifyContent='flex-end' alignItems='center'>
					<Grid item>
						<LoadingButton
							loading={store.isLoading}
							onClick={store.send}
							endIcon={<SendIcon />}
							loadingPosition="end"
							variant='outlined' >
							<span>Saada</span>
						</LoadingButton>
					</Grid>
				</Grid>
      </form>
    </Container>
  )
})
