import {computed, makeObservable, observable} from "mobx"
import {FeatureStore} from "@common/stores/FeatureStore";
import {CartStore} from "@common/stores/CartStore";
import {AlertStore} from "@common/stores/AlertStore";
import {AlertManager} from "react-alert";

export class RootStore {
  @observable featureStore: FeatureStore = FeatureStore.new()
  @observable cartStore: CartStore = CartStore.new()
  @observable alertStore: AlertStore = AlertStore.new()

  static new = () => new RootStore()

  private constructor() {
    makeObservable(this)
  }

  async init(alert: AlertManager) {
    await this.featureStore.init()
    await this.alertStore.init(alert)
  }

  @computed
  get isLoading() {
    return this.featureStore.isLoading || this.alertStore.isLoading
  }
}