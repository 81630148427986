import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import './App.scss'

import React from 'react';
import { Box, Container, } from '@material-ui/core';
import {Navigations} from "./pages";
import {FlashMessage, Footer, Navbar} from "@common/components";
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import {RootProvider} from "@common/stores";
import {ClientRouter} from "./ClientRouter";
import {createTheme, ThemeProvider} from "@mui/material";

const alertOptions = {
  position: positions.TOP_CENTER,
  timeout: 5000,
  transition: transitions.FADE
}

const theme = createTheme({
  palette: {
    primary: { main: "#01052C" },
    secondary: { main: "#A61C32" },
    error: { main: "#f44336" },
    warning: { main: "#ff9800" },
    info: { main: "#2196f3" },
    success: { main: "#4caf50" }
  },
});


const App = () => (
  <React.StrictMode>
    <ThemeProvider theme={theme}>
      <AlertProvider template={FlashMessage} {...alertOptions}>
        <RootProvider>
          <Navbar links={Navigations} />
          <Container style={{ marginTop: 10 }} maxWidth="md" className="page-root-container">
            <Box>
              <ClientRouter />
            </Box>
          </Container>
          <Footer />
        </RootProvider>
      </AlertProvider>
    </ThemeProvider>
  </React.StrictMode>
)

export default App
