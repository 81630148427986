import {action, computed, makeObservable, observable} from "mobx";
import {ApiResponse, Features, getFeatures} from "@common/service";
import {FeatureRecord} from "@common/service/features";

export class FeatureStore {
  @observable private response?: ApiResponse<Partial<FeatureRecord>> = undefined

  static new = () => new FeatureStore()

  private constructor() {
    makeObservable(this)
  }

  async init() {
    const res = await getFeatures()

    this.setResponse(res)
  }

  @computed
  get isLoading() {
    return this.response === undefined
  }

  @computed
  private get features() {
    return this.response?.data ||{}
  }

  isEnabled = (feature: Features): boolean =>
    this.features[feature] || false

  isCartEnabled = (): Boolean =>
    this.isEnabled(Features.ENABLE_CART)

  isVacationModeEnabled = (): Boolean =>
    this.isEnabled(Features.ENABLE_VACATION_MODE)

  @action private setResponse = (res: ApiResponse<Partial<FeatureRecord>>) => this.response = res
}