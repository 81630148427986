import React, { useState } from 'react'
import { Grid } from '@material-ui/core'
import { MagnifiedImage } from './MagnifiedImage';

import './ProductImage.scss';

export type ProductImageProps = {
  images: string[],
}

export const ProductImage = React.memo<ProductImageProps>(({ images }) => {
  const [activeImg, setActiveImg] = useState<number>(0)
  
  return (
    <Grid item md={6} xs={12} className='product-image'>
      <Grid justifyContent="flex-start" spacing={1} container>
        <Grid item xs={12}>
          <MagnifiedImage image={images[activeImg]} />
        </Grid>
        {images.map(image => {
          return (
            <Grid key={images.indexOf(image)} item xs={2}
              className={`small-image-container ${images[activeImg] === image ? 'active' : ''}`}>
              <img
                alt="SmallProductImage"
                id={images.indexOf(image).toString()}
                onClick={e => setActiveImg(Number((e.target as any).id))}
                src={image}
                width="100%"
              />
            </Grid>
          )
        })}
      </Grid>
    </Grid>
  )
})
