import {API} from "@common/service/api";
import {ApiResponse} from "@common/service/models";

export enum Features {
  ENABLE_CART = "ENABLE_CART",
  ENABLE_VACATION_MODE = "ENABLE_VACATION_MODE"
}

export type FeatureRecord = Record<keyof typeof Features, boolean>

export const getFeatures = async (): Promise<ApiResponse<Partial<FeatureRecord>>> =>
  (await API.get<ApiResponse<Partial<FeatureRecord>>>('/features')).data
