import {Button, Grid, TextField} from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import React from 'react'

import './AmountForm.styles.scss'

export type AmountFormProps = {
  onChange: (amount: number) => void,
  amount: number
}

export const AmountForm = React.memo<AmountFormProps>(({ onChange, amount }) => {
  function changeAmount(newAmount: number | string,) {
    if (typeof newAmount === 'string') {
      if (isNaN(Number(newAmount))) return

      newAmount = Number(newAmount)
    }

    if (newAmount <= 0) return

    onChange(newAmount)
  }

  return (
    <Grid item container xs={12} md={6} justifyContent='flex-end'>
      <div className='amount-form'>
        <Button data-testid="increase-amount" size="medium" onClick={() => changeAmount(amount + 1)}><Add fontSize='small' /></Button>
        <TextField
          inputProps={{"data-testid":"input-amount"}}
          onChange={(e) => changeAmount(e.target.value)}
          margin="dense" type="number" fullWidth={false} variant="outlined" value={amount} />
        <Button data-testid="decrease-amount" size="medium" onClick={() => changeAmount(amount - 1)}><Remove /></Button>
      </div>
    </Grid>
  )
})
