import './ProductListPageContents.scss'

import { Grid } from '@material-ui/core'
import React from 'react'
import {ProductCard} from '@common/components'
import {ProductListStore} from "./ProductListStore";
import {CircularProgress, Pagination} from "@mui/material";
import {observer} from "mobx-react";
import {ProductSearch} from "./ProductSearch";

export const ProductListPageContents = observer(({store}: { store: ProductListStore}) => {
  if (store.isInitialLoading) return <CircularProgress />

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justifyContent='center'
      className="product-list-page-contents">
      <ProductSearch store={store}/>
      <Grid
        container
        item
        sm={9}
        xs={12}
        direction="row"
        alignItems="flex-start"
        justifyContent='center'
        className="product-list-page-contents">
        {store.products.map(product => <ProductCard key={product.name} product={product} /> )}
        {store.shouldRenderPagination && <Grid item container xs={12} justifyContent='center'>
            <Pagination
                count={store.totalPages}
                page={store.page}
                onChange={(_, page) => store.setPage(page)}
                color="primary"
                shape="rounded"/>
        </Grid>}
      </Grid>
    </Grid>
  )
})
