import {Table, TableBody, TableCell, TableHead, TableRow} from "@material-ui/core";
import {TabPanel} from "@material-ui/lab";
import React from "react";
import {BulkPricesTabData} from "../../ProductViewStore";

export interface BulkPricesProductTabProps {
  data: BulkPricesTabData
}

export const BulkPricesProductTab = ({ data }: BulkPricesProductTabProps) => {

  const createLabelFragment = (label: string) =>
    label.split(/\n/).map((line) => <React.Fragment key={line}>{line}<br/></React.Fragment>)

  return (
    <TabPanel value="BULK_PRICES">
      <Table>
        <TableHead>
          <TableRow>
            {data.labels.map(label => <TableCell key={label}>{createLabelFragment(label)}</TableCell>)}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.rows.map((row, rowIndex) =>
          <TableRow key={rowIndex}>
            {row.map((x, i) => <TableCell key={`${rowIndex}-${i}`}>{x}</TableCell>)}
          </TableRow>
          )}
        </TableBody>
      </Table>
      <p className="nb">NB! Hulgihinna soodustus arvutatakse ostukorvis.</p>
    </TabPanel>
  )
}