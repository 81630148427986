import './CartPage.scss'

import { Button, Grid, Radio, FormControlLabel, RadioGroup, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@material-ui/core'
import React, { useState } from 'react'
import { useAlert } from 'react-alert'
import { Close } from '@material-ui/icons'
import {observer} from "mobx-react";
import {useRootStore} from "@common/stores";
import {CartPageStore} from "./CartPageStore";
import {MetaTags} from "@common/components";
import {normalizePrice, Paths} from "@common/util";
import {DeliveryMethod} from "@common/service";
import {CartPageBackdrop} from "./CartPageBackdrop";

export const CartPage = observer(() => {
  const alertManager = useAlert()
	const { cartStore } = useRootStore()
	const [store] = useState(() => {
		const store = CartPageStore.new(cartStore, alertManager)
		store.init()

		return store
	})

	if (store.isLoading) return <></>

  return (
		<Grid className='cart-page' container>
			<MetaTags
				title='Ostukorv'
				description=''
				link={Paths.CART}
			/>
      <CartPageBackdrop
				showBackDrop={store.showBackdrop}
				onSetDeliveryDate={store.setDeliveryDate}
				onCancel={() => store.setShowBackdrop(false)}
				onSubmit={store.performCheckout}
			/>
      <Grid item xs={12} container>
        <Grid item xs={12}><Typography variant='h1'>Tellimus</Typography></Grid>
        <Grid item xs={12} className='contents'>
					{store.isCartEmpty && <Typography align='center' variant='h3'>Tellimus on tühi</Typography>}
					{!store.isCartEmpty && <TableContainer>
						<Table>
							<TableHead>
								<TableRow>
									<TableCell>Kingitus</TableCell>
									<TableCell width="9%" align='center'>Hind</TableCell>
									<TableCell width={160} align='center'>Kogus</TableCell>
									<TableCell width="9%" align='center'>Vahesumma</TableCell>
									<TableCell width="8%"></TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{store.cartItems.map(item => (
									<TableRow key={`${item.productId}-${item.variantId}`}>
										<TableCell>
											{item.productName}<br></br>
											{item.variantName}
										</TableCell>
										<TableCell align='center'>{normalizePrice(item.finalPrice / item.amount)} €</TableCell>
										<TableCell align='center'>{item.amount}</TableCell>
										<TableCell align='center'>{normalizePrice(item.finalPrice)} €</TableCell>
										<TableCell align='right'>{<button onClick={() => cartStore.removeFromCart(item.productId, item.variantId)}>
											<Close className='icon' />
										</button>}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</TableContainer>}
        </Grid>
      </Grid>
      {!store.isCartEmpty && <Grid item xs={12} spacing={5} container justifyContent='flex-end' alignItems='flex-start'>
				<Grid item xs={12}>
					<Table className="sum-table">
						<TableBody>
							<TableRow>
								<TableCell>Vahesumma km-ta</TableCell>
								<TableCell>{store.finalPrice}</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Transport</TableCell>
								<TableCell>
									<RadioGroup value={store.deliveryMethod} onChange={(e) => store.setDeliveryMethod(DeliveryMethod[e.target.value as keyof typeof DeliveryMethod])}>
										<FormControlLabel value={DeliveryMethod.COURIER} control={<Radio size="small" />} label="Kuller (25€)" />
										<FormControlLabel value={DeliveryMethod.PICK_UP} control={<Radio size="small" />} label="Ise järele" />
									</RadioGroup>
								</TableCell>
							</TableRow>
							<TableRow>
								<TableCell>Summa km-ga</TableCell>
								<TableCell>{store.finalPriceWithVat}</TableCell>
							</TableRow>
						</TableBody>
					</Table>
				</Grid>
				<Grid item xs={12} md={6}>
					<Button color="primary" className="format-order" onClick={() => store.setShowBackdrop(true)} variant='contained'>Vormista Tellimus</Button>
				</Grid>
      </Grid>}
    </Grid>
  )
})
