import {Divider, Grid} from '@material-ui/core'
import React from 'react'
import { Carousel } from 'react-bootstrap'
import {MetaTags, PageTitle, ProductCard} from "@common/components";
import {HomePageStore} from "./HomePageStore";
import {observer} from "mobx-react";
import {useStore} from "@common/stores";
import {Paths} from "@common/util";

export const HomePage = observer(() => {
	const store = useStore(HomePageStore.new)

	return (
		<div className='home-page'>
			<MetaTags
				title='Avaleht'
				description='Kui on soov tavapäraste kommipakkide asemel üllatada lapsi vahva mänguasja, lauamängu või mõne muu toreda kingitusega, siis tasub jõulukontori usinate päkapikkudega kindlasti ühendust võtta. Oleme proovinud, testinud, katsetanud ning lõpuks kokku pannud rea imelisi jõulupakke, millega ettevõtte väikeseid sõpru jõulude ajal rõõmustada. Usume, et pärast suurt pidu võiks lapsele jääda midagi ka peale kommipaberite ja teame, et ka pisike, kuid nutikas kingitus, teeb lapse meele rõõmsaks.'
				link={Paths.HOME}
			/>
			<Divider />
			<Carousel controls={false} indicators={false} interval={5000}>
				<Carousel.Item>
					<img className='d-block w-100' src={store.bannerImage} alt='carousel 1' />
				</Carousel.Item>
			</Carousel>
			{store.shouldShowProducts && <>
				<a href="/products" style={{ textDecoration: 'none' }}><PageTitle h2={true}>Kingitused</PageTitle></a>
				<Grid
					container
					direction="row"
					alignItems="flex-start"
					justifyContent='center' >
					{store.products.map(product => <ProductCard key={product.name} product={product} /> )}
				</Grid>
			</>
			}
		</div>
	)
})
