import { Grid } from '@material-ui/core'
import React, {useEffect, useState} from 'react'
import {PageTitle, MetaTags} from '@common/components'
import {ProductListStore} from "./ProductListStore";
import {observer} from "mobx-react";
import {ProductListPageContents} from "./ProductListPageContents";
import {useHistory, useLocation} from "react-router-dom";
import {isNumeric, Paths} from "@common/util";

const ProductListPage = observer(() => {
  const history = useHistory();
  const location = useLocation();
  const [ store ] = useState(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const page = urlParams.get("page")
    const productGroups = urlParams.get("productGroups")

    const store = ProductListStore.new({
      page: isNumeric(page) ? parseInt(page as string) : undefined,
      productGroups: productGroups ? productGroups.split(",") : undefined
    })
    store.init()

    return store
  })

  useEffect(() => {
    store.fetchProducts()

    const filters: any = {}

    if (store.shouldRenderPagination) filters.page = store.page.toString()
    if (store.productGroupsIds.length > 0) filters.productGroups = store.productGroupsIds.join(",")

    history.replace({ pathname: location.pathname, search: new URLSearchParams(filters).toString() });
  }, [store.page, store.productGroupsIds, store.limit])

  return (
    <Grid
      container
      direction="row"
      alignItems="flex-start"
      justifyContent='center'
			spacing={5}>
			<MetaTags
				title='Kingitused'
				description='Kui on soov tavapäraste kommipakkide asemel üllatada lapsi vahva mänguasja, lauamängu või mõne muu toreda kingitusega, siis tasub jõulukontori usinate päkapikkudega kindlasti ühendust võtta. Oleme proovinud, testinud, katsetanud ning lõpuks kokku pannud rea imelisi jõulupakke, millega ettevõtte väikeseid sõpru jõulude ajal rõõmustada. Usume, et pärast suurt pidu võiks lapsele jääda midagi ka peale kommipaberite ja teame, et ka pisike, kuid nutikas kingitus, teeb lapse meele rõõmsaks.'
				link={Paths.PRODUCT_LIST}
			/>
      <PageTitle>Kingitused</PageTitle>
      <ProductListPageContents store={store} />
    </Grid>
  );
})

export default ProductListPage
