import App from './App'
import reportWebVitals from './reportWebVitals'
import ReactDOM from 'react-dom'
import * as Sentry from "@sentry/react";
import {Config, SENTRY_CONFIG} from "@common/util";

if (Config.sentryDSN) Sentry.init(SENTRY_CONFIG);

ReactDOM.render(App(), document.getElementById('root'));

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
