import './Navbar.scss'

import React, {useMemo} from 'react'
import { Container } from '@material-ui/core'
import { Navbar as RBNavbar, Container as RBContainer, Nav, NavLinkProps } from 'react-bootstrap'
import {useRootStore} from "@common/stores";
import {checkFeature, WithFeatures} from "@common/util";

export interface NavLinkPropsWithFeature extends WithFeatures, NavLinkProps { }

export type NavbarProps = {
  links: NavLinkPropsWithFeature[]
}

export const Navbar = React.memo<NavbarProps>(({ links }) => {
  const { featureStore } = useRootStore()

  const enabledLinks = useMemo(
    () => links.filter(link => checkFeature(link, featureStore)),
    [featureStore, links]
  )

  return (
    <Container maxWidth='md' className='navbar'>
      <RBNavbar expand="md">
        <RBContainer >
          <RBNavbar.Brand href="/">
            <img className='brand' alt="lelulaegas" src="/logo120.png" />
          </RBNavbar.Brand>
          <RBNavbar.Toggle aria-controls="basic-navbar-nav" />
          <RBNavbar.Collapse id="basic-navbar-nav" className='collapse'>
            <Nav>
              {enabledLinks.map(link =>
                typeof link.children === 'object' ?
                  // @ts-ignore
                  <Nav.Link className="nav-item-icon" key={link.href} {...link}><link.children /></Nav.Link> :
                  <Nav.Link key={link.href} {...link} />
              )}
            </Nav>
          </RBNavbar.Collapse>
        </RBContainer>
      </RBNavbar>
    </Container>
  )
})
