import {AxiosRequestConfig} from "axios";
import {FirebaseOptions} from "@firebase/app";
import {BrowserOptions} from "@sentry/react";
import * as Sentry from "@sentry/react";

export const Config = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
  sentryDSN: process.env.REACT_APP_SENTRY_DSN,
  instagramUrl: "https://www.instagram.com/lelulaeka.joulukontor"
}

export const AXIOS_CONFIG: AxiosRequestConfig = {
  baseURL: Config.baseUrl
}

export const FIREBASE_CONFIG: FirebaseOptions = {
  apiKey: Config.apiKey,
  authDomain: Config.authDomain,
  projectId: Config.projectId,
  storageBucket: Config.storageBucket,
  messagingSenderId: Config.messagingSenderId,
  appId: Config.appId,
  measurementId: Config.measurementId
};


export const SENTRY_CONFIG: BrowserOptions = {
  dsn: Config.sentryDSN,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay(),
  ],
  // Performance Monitoring
  tracesSampleRate: .1, // Capture 100% of the transactions, reduce in production!
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
}
