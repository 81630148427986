import {FeatureStore} from "@common/stores";
import {Features} from "@common/service";

export interface WithFeatures {
  featureEnabled?: Features
  featureDisabled?: Features
}

export const checkFeature = (object: WithFeatures, featrureStore: FeatureStore): boolean => {
  const isFeatureEnabled = object.featureEnabled ? featrureStore.isEnabled(object.featureEnabled) : true
  const isFeatureDisabled = object.featureDisabled ? !featrureStore.isEnabled(object.featureDisabled) : true

  return isFeatureEnabled && isFeatureDisabled
}