import {ContactFormData, ContactsForm, DatepickerForm} from "@common/components";
import {Backdrop} from "@material-ui/core";
import React, {useCallback, useEffect, useState} from "react";
import {DateTime} from "luxon";
import {MIN_DATE} from "./CartPageStore";

export interface CartPageBackdropProps {
  showBackDrop: boolean
  onSetDeliveryDate: (date: DateTime) => void
  onCancel: () => void
  onSubmit: (data: ContactFormData) => void
}

export const CartPageBackdrop = (props: CartPageBackdropProps) => {
  const { showBackDrop, onSubmit, onCancel, onSetDeliveryDate } = props

  const [showDeliveryDateModal, setShowDeliveryDateModal] = useState(false)
  const [showContactModal, setShowContactModal] = useState(false)

  const onSubmitDatepicker = useCallback((date: DateTime) => {
    onSetDeliveryDate(date); setShowDeliveryDateModal(false); setShowContactModal(true)
  }, [onSetDeliveryDate, setShowDeliveryDateModal, setShowContactModal])

  // Start checkout flow
  useEffect(() => {
    if (showBackDrop) {
      setShowDeliveryDateModal(true)
      setShowContactModal(false)
    }
  }, [showBackDrop])

  return (
    <Backdrop open={showBackDrop} >
      {showDeliveryDateModal && <DatepickerForm
          minDate={MIN_DATE}
          onSubmit={onSubmitDatepicker}
          onCancel={onCancel}
      />}
      {showContactModal && <ContactsForm dontValidateDescription onCancel={onCancel} onSubmit={onSubmit} />}
    </Backdrop>
  )
}