import { Card, CardContent, CardMedia, Grid,Typography } from '@material-ui/core'
import React from 'react'
import { Product } from '@common/service';

import './ProductCard.scss'
import {formatPrice, generatePath, generateProductViewPath, Paths} from "@common/util";

export type ProductCardProps = {
  product: Product
}

export const ProductCard = React.memo<ProductCardProps>(({ product }) => {
  return (
    <Grid className='product-card' item md={4} xs={12}>
			<a style={{ textDecoration: 'none' }} href={generateProductViewPath(product.name)}>
				<Card className='card' elevation={0}>
					<CardMedia className='media'
						image={`${product.image.replace("view", "preview")}&width=194&height=194`}
						title={product.name} />
					<CardContent className='content'>
						<Typography align="center" variant="h5">{product.name}</Typography>
						<Typography align="center" variant="h5">{formatPrice(product.price)}</Typography>
					</CardContent>
				</Card>
			</a>
    </Grid>
  )
})
