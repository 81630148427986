import {RouteProps} from "react-router-dom";
import {AboutPage} from "./about/AboutPage";
import {ContactsPage} from "./contacts/ContactsPage";
import ProductViewPage from "./productViewPage/ProductViewPage";
import ProductListPage from "./productList/ProductListPage";
import {HomePage} from "./home";
import {CartPage} from "./cart";
import ShoppingBasketIcon from "@material-ui/icons/ShoppingBasket";
import {Features} from "@common/service";
import {NavLinkPropsWithFeature} from "@common/components";
import {Paths, WithFeatures} from "@common/util";

export interface RoutePropsFeatureEnabled extends WithFeatures, RouteProps { }

export const Routes: RoutePropsFeatureEnabled[] = [
  { path: Paths.ABOUT, component: AboutPage },
  { path: Paths.CONTACT, component: ContactsPage },
  { featureDisabled: Features.ENABLE_VACATION_MODE, path: Paths.PRODUCT_LIST, component: ProductListPage, exact: true },
  { featureDisabled: Features.ENABLE_VACATION_MODE, path: Paths.PRODUCT_LIST_OLD, component: ProductListPage, exact: true },
  { featureDisabled: Features.ENABLE_VACATION_MODE, path: Paths.PRODUCT_VIEW, component: ProductViewPage },
  { featureEnabled: Features.ENABLE_CART, featureDisabled: Features.ENABLE_VACATION_MODE, path: Paths.CART, component: CartPage },
  { path: Paths.HOME, component: HomePage }
]

export const Navigations: NavLinkPropsWithFeature[] = [
  { featureDisabled: Features.ENABLE_VACATION_MODE, href: Paths.PRODUCT_LIST, children: "Kingitused" },
  { href: Paths.ABOUT, children: "Meist" },
  { href: Paths.CONTACT, children: "Kontakt" },
  { featureEnabled: Features.ENABLE_CART, featureDisabled: Features.ENABLE_VACATION_MODE, href: Paths.CART, children: ShoppingBasketIcon }
]