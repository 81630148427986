import React, { useState } from 'react'
import { Button, Container, Grid, Typography } from '@material-ui/core'
import { CalendarPicker, LocalizationProvider } from '@mui/lab'
import DateAdapter from '@mui/lab/AdapterLuxon'
import { DateTime } from 'luxon'
import { useAlert } from 'react-alert'

export type DatepickerFormProps = {
	minDate: DateTime
	onCancel: () => void
	onSubmit: (date: DateTime) => void  
}

export const DatepickerForm = React.memo<DatepickerFormProps>(({ minDate, onCancel, onSubmit }) => {
	const alert = useAlert()

	const [date, setDate] = useState<DateTime | undefined>()

	function submit(): void {
		if (!date) {
			alert.error('Palun valige tellimuse tarne tähtaeg')
			return
		}

		onSubmit(date)
	}

	return (<Container maxWidth='sm'>
		<Grid
			container
			alignItems='center'
			justifyContent='center'
			spacing={4}>
			<Grid item xs={12}>
				<Typography variant='h2'>Tarne tähtaeg</Typography>
				<LocalizationProvider dateAdapter={DateAdapter} locale='et'>
					<CalendarPicker
						onChange={(date) => { if (date) setDate(date) }}
						minDate={minDate}
						date={date} />
				</LocalizationProvider>
			</Grid>
			<Grid item xs={12} container spacing={1} justifyContent='flex-end' alignItems='center'>
				{onCancel && <Grid item><Button onClick={onCancel} className='MuiButton-error'>Muuda tellimus</Button></Grid>}
				<Grid item><Button onClick={submit} variant="contained" color="primary">Edasi</Button></Grid>
			</Grid>
		</Grid>
	</Container>
	)
})
