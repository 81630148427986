import {action, computed, makeObservable, observable, toJS} from "mobx";
import {
  getProductFilters,
  getProducts,
  Product,
  ProductFiltersResponse,
  ProductGroupFilters,
  ProductResponse
} from "@common/service";

export interface ProductGroupFilter extends ProductGroupFilters {
  active: boolean
}

export interface QueryParams {
  page?: number,
  productGroups?: string[]
}

export class ProductListStore {
  @observable.ref protected _filters?: ProductFiltersResponse
  @observable.ref protected _products?: ProductResponse
  @observable.ref protected _page = 1
  @observable.ref protected _limit = 50
  @observable.ref protected _productGroups: string[] = []

  static new = (queryParams: QueryParams) => new ProductListStore(queryParams)

  private constructor(queryParams: QueryParams) {
    makeObservable(this)

    if (queryParams.page) this._page = queryParams.page
    if (queryParams.productGroups) this._productGroups = queryParams.productGroups
  }

  async init() {
    this.setFilters(await getProductFilters())
  }

  @computed
  get isInitialLoading(): boolean {
    return this._filters === undefined && this._products === undefined
  }

  @computed
  get products(): Product[] {
    return this._products?.data?.products || []
  }

  @computed
  get shouldRenderPagination(): boolean {
    return this.totalPages > 1 || this.page !== 1
  }

  @computed
  get totalPages(): number {
    return this._products?.data?.pagination.totalPages || 1
  }

  @computed
  get page(): number {
    return this._page
  }

  @computed
  get limit(): number {
    return this._limit
  }

  @computed
  get productGroups(): ProductGroupFilter[] {
    return this._filters?.data?.productGroups.map(productGroup => ({
      ...productGroup,
      active: this._productGroups.includes(productGroup.id)
    })) || []
  }

  @computed
  get productGroupsIds(): string[] {
    return this._productGroups
  }

  async fetchProducts() {
    this.setProducts(await getProducts({ limit: this._limit, page: this._page, productGroup: toJS(this.productGroupsIds) }))
  }

  @action setPage = (page: number) => {
    window.scrollTo(0, 0);
    this._page = page
  }
  @action toggleProductGroup = (id: string) => {
    if (this._page !== 1) this._page = 1

    if (this._productGroups.includes(id)) {
      this._productGroups = this._productGroups.filter(productGroup => productGroup !== id)
    } else this._productGroups = [...this._productGroups, id]
  }

  @action private setProducts = (res: ProductResponse) => this._products = res
  @action private setFilters = (res: ProductFiltersResponse) => this._filters = res
}