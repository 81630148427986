import './Footer.scss'

import { Container, Divider, Grid, Typography } from '@material-ui/core'
import React from 'react'
import { Phone, Email, Instagram } from '@material-ui/icons';
import {Config} from "@common/util";

export const Footer = React.memo(() => {
  return (
		<div className='footer-wrap'>
			<div className='footer' >
				<Container className='container' maxWidth='md'>
					<Divider />
					<Grid container className='details-container' alignItems='flex-start' justifyContent='center'>
						<Grid item container xs={12} md={3}>
							<Grid item xs={12}><Typography>Lelulaeka jõulukontor</Typography></Grid>
						</Grid>
						<Grid item container xs={12} md={3}>
							<Grid item xs={12}><Typography><b>Address:</b></Typography></Grid>
							<Grid item xs={12}><Typography>Mahtra 1, Tallinn<br/>(Mustakivi Kaubanduskeskus)</Typography></Grid>
						</Grid>
						<Grid item container xs={12} md={3}>
							<Grid item xs={12}><Typography><Email color='primary' fontSize='small' /> info@joulukontor.ee</Typography></Grid>
							<Grid item xs={12}><Typography><Phone color='primary' fontSize='small' /> +372 5693 9727</Typography></Grid>
							<Grid item xs={12}>
									<a target="#" href={Config.instagramUrl}><Instagram color='primary' /></a>
							</Grid>
						</Grid>
					</Grid>
					<Typography className='trademark' align='center'>Siremar Grupp OÜ © {new Date().getFullYear()}</Typography>
				</Container>
			</div>
		</div>
  )
})
