import React from "react"
import {Grid, InputAdornment, TextField as MaterialTextField} from "@material-ui/core"
import {TEXT_FIELD_ICON_MAP} from "./iconMap"
import {GridSize} from "@material-ui/core/Grid/Grid";

export interface GridProps {
	xl?: boolean | GridSize,
	lg?: boolean | GridSize,
	md?: boolean | GridSize,
	sm?: boolean | GridSize,
	xs?: boolean | GridSize
}

export interface TextFieldProps {
	autoComplete?: boolean
	rows?: number
	name: string
	grid?: GridProps
	error?: string
	icon?: keyof typeof TEXT_FIELD_ICON_MAP
	onChange?: (newValue: string) => void
	disabled?: boolean
	value: string
}


export const TextField = React.memo<TextFieldProps>((props) => {
	const {
		value,
		autoComplete,
		rows,
		name,
		grid,
		error,
		icon,
		onChange,
		disabled
	} = props

	const isMultiline = rows !== undefined
	const isError = error !== undefined
	const IconComponent = icon ? TEXT_FIELD_ICON_MAP[icon] : undefined
	const IconAdornment = IconComponent ? (
		<InputAdornment position="start">
			<IconComponent />
		</InputAdornment>
	) : undefined

	return (
		<Grid item {...grid}>
			<MaterialTextField
				value={value}
				id={name}
				error={isError}
				helperText={error}
				rows={isMultiline ? rows : undefined}
				multiline={isMultiline}
				name={name}
				autoComplete={autoComplete ? 'on' : 'off'}
				label={name}
				size='small'
				fullWidth
				color="primary"
				disabled={disabled}
				onChange={(event) => {
					if (onChange) onChange(event.target.value)
				}}
				InputProps={{
					startAdornment: IconAdornment,
				}}
			/>
		</Grid>
	)
})
