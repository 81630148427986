export type CartItem = {
  id: string,
  productId: string,
  variantId: string,
  amount: number
}

export const getCartItems = (): CartItem[] =>
  JSON.parse(localStorage.getItem('cart') || '[]')

export const saveCartItems = (cartItems: CartItem[]) =>
  localStorage.setItem('cart', JSON.stringify(cartItems))

