import React from 'react'
import { Skeleton } from '@material-ui/lab';

export type MagnifiedImageProps = {
  classes?: any,
  image?: string
}

export const MagnifiedImage = React.memo<MagnifiedImageProps>(({ classes, image }) => {
  function mouseOver(e: any) {
    const { clientX, clientY } = e
    const target = e.currentTarget ? e.currentTarget : e.target

    const { offsetLeft, offsetTop, clientWidth, clientHeight, lastChild } = target

    const x = clientWidth / 2 - (clientX - offsetLeft),
          y = clientHeight / 2 - (clientY - offsetTop)

    lastChild.style.setProperty('transform', `scale(4.5) translate(${x}px, ${y}px)`)
  }

  function mouseOut(e: any) {
    const target = e.currentTarget ? e.currentTarget : e.target
    target.lastChild.style.removeProperty('transform')
  }

  const imageComponent = image ?
    <img alt="MainProductImage" src={image} /> :
    <Skeleton variant='rect' width={500} height={500} animation='wave' />

  return (
    <figure
      className='magnified-image'
      onMouseLeave={mouseOut}
      onMouseMove={mouseOver}>
      {imageComponent}
    </figure>
  )
})

