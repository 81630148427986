import React from 'react'
import { Typography } from '@material-ui/core'

import './PageTitle.scss'

export type PageTitleProps = {
  children: string | undefined,
  h2?: boolean
}

export const PageTitle = React.memo<PageTitleProps>(({ children, h2 }) => {
  const blanks = [...new Array(5)].map(x => '\u00A0').join('')
  children = blanks + children + blanks

  return (
    <>
			<Typography className={`page-title`} variant={h2 ? 'h2' : 'h1'}>{children}</Typography>
    </>
  )
})
