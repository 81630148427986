export enum Paths {
  HOME = "/",
  ABOUT = "/meist",
  CONTACT = "/kontakt",
  PRODUCT_LIST_OLD = "/products",
  PRODUCT_LIST = "/kingitused",
  PRODUCT_VIEW = "/kingitused/:name",
  CART = "/ostukorv"
}

export const generatePath = (path: Paths, props: { [key: string]: string; }) => {
  let finalPath: string = path
  for (const key in props) {
    finalPath = finalPath.replace(`:${key}`, props[key])
  }

  return finalPath
}

export const generateProductViewPath = (name: string) =>
  generatePath(Paths.PRODUCT_VIEW, { name })