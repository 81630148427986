import {API} from "@common/service/api";

export type MessagePostData = {
  name: string,
  email: string,
  message: string
}

export async function sendMessage(data: MessagePostData): Promise<void> {
  await API.post('/messages', data)
}
