import { Button, Divider, Grid, Typography } from '@material-ui/core';
import { PageTitle, MetaTags, AmountForm } from '@common/components'
import { ShoppingCart } from '@material-ui/icons';
import React, { useState } from 'react'
import {useRootStore} from '@common/stores'
import './ProductViewPage.scss'
import {observer} from "mobx-react";
import {ProductViewStore} from "./ProductViewStore";
import {VariantsSection} from "./VariantsSection";
import { ProductTabs } from "./components/ProductTabs/ProductTabs";
import {ProductImage} from "./components/ProductImage/ProductImage";
import {generateProductViewPath} from "@common/util";

export type ProductViewPageProps = {
  match: {
    params: {
      name: string
    }
  }
}

const ProductViewPage = observer(({ match }: ProductViewPageProps) => {
	const { featureStore } = useRootStore()
  const { cartStore } = useRootStore()
  const [store] = useState(() => {
		const store = ProductViewStore.new(cartStore)
		store.init(match.params.name)

		return store
	})

	if (store.isLoading) return null

  return (
    <Grid container className='product-view' spacing={3}>
			<MetaTags
				title={store.name}
				description={store.description}
				link={generateProductViewPath(match.params.name)}
				image={store.images[0]}
			/>
			<PageTitle>{store.name}</PageTitle>
      <ProductImage images={store.images} />
      <Grid item md={6} xs={12}>
        <Grid container>
					<Grid item xs={12}>
						<Typography>{store.productCode}</Typography><br />
						<Typography className='description'>{store.description}</Typography>
						<Button size='small' href='#product-tabs' style={{ float: 'right' }} color='primary'>Loe edasi</Button>
						<Typography variant='h2' align='right'>{store.price}</Typography>
						<Divider />
					</Grid>
					<VariantsSection variants={store.variants} variantId={store.activeVariant} setVariant={(variantId) => store.setVariant(variantId)} />
					{featureStore.isCartEnabled() && <Grid item container justifyContent='flex-end' xs={12} spacing={2}>
						<AmountForm amount={store.amount} onChange={store.setAmount}/>
						<Grid item container xs={12} md={6} justifyContent='flex-end'>
							<Button onClick={() => store.saveToCart()} variant="contained" color="primary">
								<ShoppingCart/> Lisa tellimusse
							</Button>
						</Grid>
					</Grid>}
        </Grid>
      </Grid>
      <Grid id='product-tabs' className='tabs-container' item xs={12}>
        <ProductTabs description={store.description} bulkPricesData={store.bulkDiscountsTabData} />
      </Grid>
    </Grid>
  )
})

export default ProductViewPage
