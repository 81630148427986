import {action, computed, makeObservable, observable} from "mobx";
import {AlertManager} from "react-alert";

export class AlertStore {
  @observable private alertManager?: AlertManager = undefined

  static new = () => new AlertStore()

  private constructor() {
    makeObservable(this)
  }

  async init(alert: AlertManager) {
    this.setManager(alert)
  }

  @computed
  get isLoading() {
    return this.alertManager === undefined
  }

  showError(msg: string) {
    this.alertManager?.error(msg)
  }

  showSuccess(msg: string) {
    this.alertManager?.success(msg)
  }

  @action private setManager = (manager: AlertManager) => this.alertManager = manager
}