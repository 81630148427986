export interface FormatPriceProps {
  prefixWithPositiveSymbol: boolean
}

export const formatPrice = (price: number, props?: FormatPriceProps) => {
  let prefix = ""

  if (props?.prefixWithPositiveSymbol && price >= 0) prefix = "+"

  const prices = price.toString().split('.')

  if (!prices[1]) prices[1] = '00'
  else if (prices[1] && prices[1].length === 1) prices[1] = prices[1] + '0'

  return `${prefix}${prices.join(',')} € + km`
}

