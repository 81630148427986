import {action, computed, makeObservable, observable} from "mobx";
import {getFeaturedProducts, Product} from "@common/service";
import {FeatureStore, RootStore} from "@common/stores";

export class HomePageStore {
  @observable private featureStore: FeatureStore

  @observable.ref protected _products: Product[] = []

  static new = (rootStore: RootStore) => new HomePageStore(rootStore)

  private constructor({ featureStore }: RootStore) {
    makeObservable(this)

    this.featureStore = featureStore
  }

  async init() {
    if (this.shouldShowProducts) this.setProducts(await getFeaturedProducts())
  }

  @computed
  get shouldShowProducts(): boolean {
    return !this.featureStore.isVacationModeEnabled()
  }

  @computed
  get products(): Product[] {
    return this._products
  }

  @computed
  get bannerImage(): string {
    return `./banner/home-banner${this.shouldShowProducts ? "" : "-vacation"}.jpg`
  }

  @action private setProducts = (products: Product[]) => this._products = products
}