import {useRootStore} from "@common/stores/RootProvider";
import {useState} from "react";
import {RootStore} from "@common/stores/RootStore";
import {Store} from "@common/stores";

export const useStore = <T extends Store>(createStore: (rootStore: RootStore) => T) => {
  const rootStore = useRootStore()
  const [store] = useState(() => {
    const newStore = createStore(rootStore)
    if (newStore.init) {
      newStore.init()
    }

    return newStore
  })

  return store
}